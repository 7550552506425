<template>
  <UiDragscroll class="flex w-full pb-2 gap-2 overflow-auto px-4 py-1 items-stretch">
    <ClientOnly>
      <button
        v-for="size in allSizes"
        @click="setSize(size)"
        class="text-sm px-6 py-3 rounded-s no-underline flex-shrink-0 transition-colors"
        :class="[
          `bg-${chipColor}`,
          vehicleTypes.includes(size)
            ? `bg-opacity-100 text-${bgColor}`
            : 'bg-opacity-20  hover:bg-opacity-30',
        ]"
      >
        {{ $t(`home.filter.${size}`) }}
      </button>
      <div class="w-[1px] bg-black-10 shrink-0 my-1"></div>
      <button
        v-for="filter in allFilters"
        @click="setAdvancedFilter(filter)"
        class="text-sm px-6 py-3 rounded-s no-underline flex-shrink-0 transition-colors"
        :class="[
          `bg-${chipColor}`,
          advancedFilters.includes(filter)
            ? `bg-opacity-100 text-${bgColor}`
            : 'bg-opacity-20 hover:bg-opacity-30',
        ]"
      >
        {{ $t(`home.filter.${filter}`) }}
      </button>
    </ClientOnly>
    <slot></slot>
  </UiDragscroll>
</template>

<script lang="ts" setup>
import type { VehicleType, AdvancedFilter } from '@/types/backend';

const { advancedFilters, vehicleTypes, setAdvancedFilter, setSize } = useSearch();
const allFilters: Array<AdvancedFilter> = ['electric', 'awd', 'automatic'];
const allSizes: Array<VehicleType> = ['S', 'M', 'L'];

defineProps<{
  chipColor: 'purple' | 'pink';
  bgColor: 'white' | 'purple';
}>();
</script>
